Opentip.styles.kinderstralen =
{
	extends: 'dark',
	background: '#f15a3b',
	closeButtonCrossColor: '#b7452e',
	borderRadius: 0,
	shadow: false,
	borderWidth: 0,
	delay: 0
};

Opentip.defaultStyle = 'kinderstralen';

/* ==========================================================================
   Datatable
   ========================================================================== */

if( $('.table-datatable').length )
{
	$('.table-datatable').each(function() {

		let perPage = parseInt($(this).attr('data-per-page'));

		if(isNaN(perPage)) {
			perPage = 50
		}

		$.extend($.fn.dataTable.defaults, {
			lengthChange: false,
			info: false,
			pagingType: 'simple_numbers',
			dom: '<"datatable-header"fl>rt<"datatable-footer"pi>',
			language: {
				"processing": "Bezig...",
				"lengthMenu": "_MENU_ resultaten weergeven",
				"zeroRecords": "Geen resultaten gevonden",
				"info": "_START_ tot _END_ van _TOTAL_ resultaten",
				"infoEmpty": "Geen resultaten om weer te geven",
				"infoFiltered": " (gefilterd uit _MAX_ resultaten)",
				"infoPostFix": "",
				"search": "_INPUT_",
				"searchPlaceholder": "Zoeken..",
				"emptyTable": "Geen resultaten aanwezig in de tabel",
				"thousands": ".",
				"loadingRecords": "Een moment geduld aub - bezig met laden...",
				"paginate": {
					"first": "Eerste",
					"last": "Laatste",
					"next": "Volgende",
					"previous": "Vorige"
				}
			},
			iDisplayLength: perPage,
			initComplete: function (settings, json) {
				this.api().columns('.select-filter').every(function () {
					var column = this;
					var select = $('<select><option value="">Maak uw keuze</option></select>')
						.wrap('<div class="dataTables_column_filter"></div>')
						.parent().appendTo($(settings.nTableWrapper).find('.datatable-header'))
						.children('select').on('change', function () {
							var val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);

							if (val) {
								var options = [
									'^' + val + '$',
									'^' + val + ',',
									', ' + val + '$',

								];
								var regExp = '(' + options.join('|') + ')';
							} else {
								var regExp = '';
							}

							column
								.search(regExp, true, false)
								.draw();
						});

					var values = [];

					column.data().unique().sort().each(function (d, j) {
						var columnValues = d.split(', ');
						for (var i in columnValues) {
							if ($.inArray(columnValues[i], values) == -1)
								values.push(columnValues[i]);
						}
					});

					values.sort();

					for (var i in values) {
						var val = values[i];
						select.append('<option value="' + val + '">' + val + '</option>');
					}
				});
			}
		});
	});
}

/* ==========================================================================
   Validate
   ========================================================================== */

jQuery.extend(jQuery.validator.messages, {
        required: "Dit is een verplicht veld.",
        remote: "Controleer dit veld.",
        email: "Vul hier een geldig e-mailadres in.",
        url: "Vul hier een geldige URL in.",
        date: "Vul hier een geldige datum in.",
        dateISO: "Vul hier een geldige datum in (ISO-formaat).",
        number: "Vul hier een geldig getal in.",
        digits: "Vul hier alleen getallen in.",
        creditcard: "Vul hier een geldig creditcardnummer in.",
        equalTo: "Vul hier dezelfde waarde in.",
        accept: "Vul hier een waarde in met een geldige extensie.",
        maxlength: jQuery.validator.format("Vul hier maximaal {0} tekens in."),
        minlength: jQuery.validator.format("Vul hier minimaal {0} tekens in."),
        rangelength: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1} tekens."),
        range: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1}."),
        max: jQuery.validator.format("Vul hier een waarde in kleiner dan of gelijk aan {0}."),
        min: jQuery.validator.format("Vul hier een waarde in groter dan of gelijk aan {0}."),

        require_from_group: jQuery.validator.format("Vul {0} van deze velden in."),
        postalcodeNL: 'Vul een geldige postcode in',
});

if($('[data-toggle="validate"]').length)
{
	$('[data-toggle="validate"]').validate();
}

/* ==========================================================================
	Navigation & Header 
   ========================================================================== */
   
$( '.search form' ).submit(function(e) 
{
	$parent = $(this).closest('.search');
	
	var $input = $('input[type="text"]', this);
	if($input.val() == '' && !$parent.hasClass('active'))
	{
		// Focus the input when its empty
		$parent.addClass('active');
		
		$input.focus();
		
		return false;
	}
	else if($input.val() == '')
	{
		// Hide input field again
		$parent.removeClass('active');
		
		return false;
	}
});

$( '.search form input[type="text"]' ).focus(function()
{
	$parent = $(this).closest('.search');

	if(!$parent.hasClass('active'))
		$parent.addClass('active');
});

if($('.desktop-nav ol.menu-list').is(':visible'))
{
	$('.desktop-nav ol.menu-list>li').each(function()
	{
		var $ul = $(this).find('ol');
		var width = $(this).width();
		$(this).width( width );
		$ul.width( width );
		
		$(this).mouseenter(function()
		{
			var currentActive = $(this).closest('ol.menu-list').find('>li ol.active');
			
			if( !$ul.length && currentActive.length )
			{
				// Slide down and show this submenu
				currentActive.slideUp(200, 'easeOutQuad', function()
				{
					$(this).removeClass('active');
				});
			}
			else if(currentActive.length)
			{
				// Hide other submenus and show this submenu
				currentActive.fadeOut(200, 'easeOutQuad', function()
				{
					$(this).removeClass('active');
				});
				
				$ul.fadeIn(200, 'easeOutQuad', function()
				{
					$(this).addClass('active');
				});
			}
			else
			{
				// Slide down and show this submenu
				$ul.slideDown(200, 'easeOutQuad', function()
				{
					$(this).addClass('active');
				});
			}
		});
	});
	$('.desktop-nav').mouseleave(function()
	{
		$(this).find('ol.menu-list ol').slideUp(200, 'easeOutQuad', function()
		{
			$(this).removeClass('active');
		});
	});
}

$( 'nav.main .menu-btn' ).click(function(e)
{
	e.preventDefault();
	
    $('.desktop-nav ol.menu-list').toggleClass('expand')
});


$( '.menu-expand' ).click(function(e)
{
	e.preventDefault();
	
    $('ul.subsite-nav').toggleClass('expand')
});

// $(window).on('scroll', function()
// {
// 	if ($(this).scrollTop() > 160)
// 		$('.desktop-nav nav').addClass('fixed');
// 	else
// 		$('.desktop-nav nav').removeClass('fixed');
// });

// var footerTop = 0;
// if($(window).width() >= 1024)
// {
// 	var asideFixed = false;
//
// 	var $container = $('#main-wrapper>section>.container');
// 	var $aside = $('>aside', $container);
// 	var $footer = $('body>footer');
//
// 	if($aside.length)
// 	{
// 		if($aside.siblings('.content').height() >= $aside.height())
// 			asideFixed = true;
//
// 		footerTop = $footer.offset().top;
// 	}
//
// 	$(window).on('scroll', function()
// 	{
// 		var distanceY = $(this).scrollTop();
//
// 		// hide searchbutton
// 		if (distanceY > 170)
// 			$('.search').addClass('hide');
//
// 		else
// 			$('.search').removeClass('hide');
//
// 		// Aside
// 		if(asideFixed)
// 		{
// 			// Prepare sidebar for fixed state
// 			if (distanceY > 430)
// 				$aside.addClass('fixed');
// 			else
// 				$aside.removeClass('fixed');
//
// 			// Make sure the sidebar doesn't intersect with footer
// 			var asideTransform = parseFloat($aside.data('transformTop'));
// 			if(isNaN(asideTransform)) asideTransform = 0;
// 			var asideTop = $aside.offset().top - parseFloat($aside.css('margin-top').replace(/auto/, 0)) + asideTransform;
// 			var asideBottom = asideTop + $aside.height();
//
// 			if( asideBottom >= footerTop )
// 			{
// 				var top = asideBottom - footerTop;
// 				$aside
// 					.css({
// 						'-webkit-transform': 'translateY(' + -top + 'px)',
// 						'-ms-transform': 'translateY(' + -top + 'px)',
// 						'transform': 'translateY(' + -top + 'px)'
// 					})
// 					.data('transformTop', top);
// 			}
// 			else
// 			{
// 				$aside
// 					.css({
// 						'-webkit-transform': 'translateY(0)',
// 						'-ms-transform': 'translateY(0)',
// 						'transform': 'translateY(0)'
// 					})
// 					.data('transformTop', 0);
// 			}
// 		}
//
// 		// Scroll image
// 		if((distanceY + $(window).height() + 100) <= $(document).height())
// 			$('.scroll_down').removeClass('hide');
// 		else
// 			$('.scroll_down').addClass('hide');
// 	});
// }

$(window).trigger('scroll');

/* ==========================================================================
	Plugin calling
   ========================================================================== */

if($('.tabs').length)
{
	$('.tabs').tabs();

	$('.tabs').on('tabs.clicked', function()
	{
		footerTop = $footer.offset().top;

		$(window).trigger('scroll');
	});
}

$('.fancybox').fancybox();

function addDatePickerTo($element)
{
	var yearRange = '-1:+10';
	if(typeof $element.attr('data-year-range') != 'undefined')
		yearRange = $element.data('yearRange');

	var beforeShowDay = null;
	if(typeof $element.attr('data-disable-days') != 'undefined')
	{
		beforeShowDay = function(date)
		{
			var day = '' + date.getDay();
			var hideDays = $element.data('disable-days').split(',');
			return [($.inArray(day, hideDays) == -1), '']
		}
	}

	$element.datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: 'dd-mm-yy',
		yearRange: yearRange,
		showWeek: true,
		beforeShowDay: beforeShowDay
	});
}

$('[data-toggle="datepicker"]').each(function()
{
	addDatePickerTo($(this));
});

$('input, textarea').placeholder();

/* ==========================================================================
	Slider
   ========================================================================== */

$('#slider').each(function()
{
	// Set slider height
	var height = $(this).height();
	$(this).height( height );
	$(this).find('>ol>li').height( height );

    $(this).on('show-next', function()
    {
        var $active = $(this).find('li.active');
        
        var $next = $active.next();
        if( ! $next.length) $next = $active.parent().children('*:first-child');
        
        $next.addClass('active');
        setTimeout(function()
        {
           $active.removeClass('active').appendTo( $active.parent() );
        }, 500);
        
        $(this).trigger('set-timeout');
    });
    
    $(this).on('set-timeout', function()
    {
        var $slider = $(this);
        
        $slider.data('timeout', setTimeout(function()
        {
            $slider.trigger('show-next');
        }, 3500));
    });
    
    $(this).on('clear-timeout', function()
    {
        var timeout = $(this).data('timeout');
        
        if(typeof timeout != 'undefined')
            clearTimeout(timeout);
    });
    
    $(this).hover(function()
    {
        $(this).trigger('clear-timeout');
    }, function()
    {
        $(this).trigger('set-timeout');
    });
    
    $(this).trigger('set-timeout');
});

/* ==========================================================================
	Category filter
   ========================================================================== */

$('select.filter').change(function() {
	this.options[this.selectedIndex].value && (window.location = this.options[this.selectedIndex].value);
});

/* ==========================================================================
	Frequently Asked Questions
   ========================================================================== */
   
if($( '.faq-item' ).length)
{
	$( '.faq-item' ).click(
		function() {
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
			}
			else {
				$('.faq-item').removeClass('active');
				$(this).addClass('active');
			}
		}
	);
}

/* ==========================================================================
	Contact
   ========================================================================== */
   
if($('#gmap_canvas').length)
{
	function init_map(){var myOptions = {scrollwheel: false,zoom:16,center:new google.maps.LatLng(52.1012211,5.089345499999943),mapTypeId: google.maps.MapTypeId.ROADMAP};map = new google.maps.Map(document.getElementById("gmap_canvas"), myOptions);marker = new google.maps.Marker({map: map,position: new google.maps.LatLng(52.1012211, 5.089345499999943)});infowindow = new google.maps.InfoWindow({content:"<b>Stichting Lunchen op School</b><br/>Cab Rondom 90<br/> Utrecht" });google.maps.event.addListener(marker, "click", function(){infowindow.open(map,marker);});}google.maps.event.addDomListener(window, 'load', init_map);
}

video = document.createElement('video');
if (video.canPlayType('video/webm; codecs="vp8, vorbis"') === '') {document.getElementById('trucje').style.display = 'block';document.getElementById('video-logo').style.display='none';}